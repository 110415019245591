<template>
    <div class="row mx-4" v-if="configuration">
        <div class="col-5">
            <mercur-card class="configuration-card">
                <h2 class="font-weight-normal">{{configuration.serviceLevelName}}</h2>
                <mercur-select data-test="ServiceLevelConfigurationConfiguratorCountryFromSelect" v-model="$v.configuration.countryFrom.$model" @keypress.enter.prevent="() => {}">
                    <template slot="label">Country from</template>
                    <option
                        v-for="(country, key) in countries"
                        :key="key"
                        :value="country.countryShortCode">
                        {{country.countryName}}
                    </option>
                </mercur-select>
                <mercur-select data-test="ServiceLevelConfigurationConfiguratorCountryToSelect" v-model="$v.configuration.countryTo.$model" @keypress.enter.prevent="() => {}">
                    <template slot="label">Country to</template>
                    <option
                        v-for="(country, key) in countries"
                        :key="key"
                        :value="country.countryShortCode">
                        {{country.countryName}}
                    </option>
                </mercur-select>
                <mercur-input
                    v-model.number="$v.configuration.transitDays.$model"
                    data-test="ServiceLevelConfigurationConfiguratorTransitDaysInput"
                    type="number"
                    min="0"
                    :class="{'form-invalid': $v.configuration.transitDays.$error}">
                    Transit days
                    <template slot="note">
                        <span class="form-error" v-if="!$v.configuration.transitDays.required">Value is required</span>
                    </template>
                </mercur-input>
            </mercur-card>
        </div>
        <div data-test="col2" class="col-7">
            <mercur-card v-for="(field, key) in $v.configuration.configuration.$model" :key="key" class="mb-4 ml-4">
                <range-charges-dynamic
                    data-test="rangeChargeDynamic"
                    v-if="isChargesField(field, key)"
                    v-model="$v.configuration.configuration[key].$model"
                    :v="$v.configuration.configuration[key]"
                >
                    {{key}}
                </range-charges-dynamic>
                <limit-dynamic
                data-test="limitDynamic"
                    v-else-if="isLimitsField(field, key)"
                    v-model="$v.configuration.configuration[key].$model"
                    :v="$v.configuration.configuration[key]"
                >
                    {{key}}
                </limit-dynamic>
                <surcharges-dynamic
                    data-test="surchargesDynamic"
                    v-else-if="isSurchargesField(field, key)"
                    v-model="$v.configuration.configuration[key].$model"
                    :v="$v.configuration.configuration[key]"
                >
                    {{key}}
                </surcharges-dynamic>

                <pallet-costs-dynamic
                    data-test="palletCostsDynamic"
                    v-else-if="isPalletCostsField(field, key)"
                    v-model="$v.configuration.configuration[key].$model"
                    :v="$v.configuration.configuration[key]"
                >
                    {{key}}
                </pallet-costs-dynamic>

                <template v-else>
                    {{key}}
                    {{logUnmappedConfiguration(key, field)}}
                </template>
            </mercur-card>
        </div>
        <portal to="actionbar">
            <mercur-button
                data-test="ServiceLevelConfigurationConfiguratorSaveButton"
                class="btn btn-raised btn-yellow text-uppercase mb-4"
                :disabled="$v.$invalid || loading"
                @click="saveConfiguration"
                v-if="isAllowedTo('updateServiceLevelConfiguration')">
                Save
            </mercur-button>
        </portal>
    </div>
</template>

<script>

import { validationMixin } from 'vuelidate'
import { required, requiredIf, minLength } from 'vuelidate/lib/validators'
import RangeChargesDynamic from '@/components/carriers/ServiceLevelConfigurations/RangeChargesDynamic'
import LimitDynamic from '@/components/carriers//ServiceLevelConfigurations/LimitDynamic'
import { validator as dimensionValidator2d } from '@/components/carriers//ServiceLevelConfigurations/Fields/Dimension2d'
import { validator as dimensionValidator3d } from '@/components/carriers//ServiceLevelConfigurations/Fields/Dimension3d'
import SurchargesDynamic from '@/components/carriers//ServiceLevelConfigurations/SurchargesDynamic'
import PalletCostsDynamic from '@/components/carriers//ServiceLevelConfigurations/PalletCostsDynamic'
import countries from 'country-region-data'

const validations = {
    rangesOrCharge: {
        ranges: {
            required: requiredIf(model => model && model.type !== 'cost'),
            minLength: minLength(1),
            $each: {
                charge: {
                    required,
                },
                rangeStart: {
                    required,
                },
                rangeEnd: {
                    required,
                },
            },
        },
        charge: {
            required: requiredIf(model => model && model.type === 'cost'),
        },
    },
    rangesOrChargeOrFirstAndAdditionalPack: {
        ranges: {
            required: requiredIf(model => model && model.type !== 'cost' && !model.firstPack),
            minLength: minLength(1),
            $each: {
                charge: {
                    required,
                },
                rangeStart: {
                    required,
                },
                rangeEnd: {
                    required,
                },
            },
        },
        charge: {
            required: requiredIf(model => model && model.type === 'cost' && !model.firstPack),
        },
        firstPack: {
            required: requiredIf(model => model && model.type !== 'cost' && !model.ranges),
            charge: {
                required: requiredIf(model => typeof model !== 'undefined'),
            },
        },
        extraPack: {
            required: requiredIf(model => model && model.type !== 'cost' && !model.ranges),
            charge: {
                required: requiredIf(model => typeof model !== 'undefined'),
            },
        },
    },
    ranges: {
        ranges: {
            required: requiredIf(model => model && model.type !== 'cost'),
            minLength: minLength(1),
            $each: {
                charge: {
                    required,
                },
                rangeStart: {
                    required,
                },
                rangeEnd: {
                    required,
                },
            },
        },
    },
    rangesDimensions3d: {
        ranges: {
            required: requiredIf(model => model && model.type !== 'cost'),
            minLength: minLength(1),
            $each: {
                charge: {
                    required,
                },
                rangeStart: {
                    required,
                    format: dimensionValidator3d,
                },
                rangeEnd: {
                    required,
                    format: dimensionValidator3d,
                },
                $meta: {
                    dimensions: true,
                    dimensions3d: true,
                },
            },
        },
    },
    rangesDimensions2d: {
        ranges: {
            required: requiredIf(model => model && model.type !== 'cost'),
            minLength: minLength(1),
            $each: {
                charge: {
                    required,
                },
                rangeStart: {
                    required,
                    format: dimensionValidator2d,
                },
                rangeEnd: {
                    required,
                    format: dimensionValidator2d,
                },
                $meta: {
                    dimensions: true,
                    dimensions2d: true,
                },
            },
        },
    },
    amountOrPercentage: {
        type: {
            type: value => ['percentage', 'cost', 'amount'].includes(value),
        },
        charge: {
            required,
        },
    },

    percentage: {
        charge: {
            required,
        },
    },

    charge: {
        charge: {
            required,
        },
    },
    limit: {
        limit: {
            required,
        },
    },
    palletCost: {

    },
}

export default {
    name: 'ServiceLevelConfigurationConfigurator',
    components: { PalletCostsDynamic, SurchargesDynamic, LimitDynamic, RangeChargesDynamic },
    mixins: [validationMixin],
    props: ['configuration', 'loading'],
    data () {
        return {
            countries,
        }
    },
    methods: {
        isChargesField (field, key) {
            return [
                'chargePerPack',
                'additionalWeight',
                'residentialPrice',
                'residentialCost',
                'residentialPercentage',
                'chargePerShipment',
                'chargePerExtraPack',
                'specialSize',
                'specialWeight',
                'remoteArea',
                'remoteAreaPercentage',
                'specialGirthSize',
                'upsLengthGirthSpecialSize',
                'minimumCharge',
            ].includes(key)
        },
        isLimitsField (field, key) {
            return [
                'girthLimits',
                'weightLimits',
                'widthLimits',
                'heightLimits',
                'lengthLimits',
            ].includes(key)
        },
        isSurchargesField (field, key) {
            return [
                'fuelSurcharge',
                'tollSurcharge',
                'securitySurcharge',
            ].includes(key)
        },

        isPalletCostsField (field, key) {
            return [
                'palletCost',
            ].includes(key)
        },
        logUnmappedConfiguration (key, field) {
            console.log(key, field)
        },
        saveConfiguration () {
            this.$emit('saveConfiguration', this.configuration)
        },
    },
    validations () {
        const fullValidations = {
            configuration: {
                transitDays: {
                    required,
                },
                countryFrom: {
                    required,
                },
                countryTo: {
                    required,
                },
                configuration: {
                    chargePerShipment: validations.rangesOrCharge,
                    chargePerPack: validations.rangesOrChargeOrFirstAndAdditionalPack,
                    additionalWeight: validations.ranges,

                    residentialPrice: validations.charge,
                    residentialCost: validations.charge,
                    residentialPercentage: validations.charge,
                    chargePerExtraPack: validations.charge,
                    girthLimits: validations.limit,
                    weightLimits: validations.limit,
                    widthLimits: validations.limit,
                    heightLimits: validations.limit,
                    lengthLimits: validations.limit,
                    specialSize: validations.rangesDimensions3d,
                    specialWeight: validations.ranges,
                    fuelSurcharge: validations.amountOrPercentage,
                    tollSurcharge: validations.amountOrPercentage,
                    securitySurcharge: validations.charge,

                    palletCost: validations.palletCost,

                    remoteArea: validations.charge,
                    remoteAreaPercentage: validations.percentage,

                    specialGirthSize: validations.ranges,
                    upsLengthGirthSpecialSize: validations.rangesDimensions2d,

                    minimumCharge: validations.charge,
                },
            },
        }

        for (const validationName in fullValidations.configuration.configuration) {
            if (
                fullValidations.configuration.configuration.hasOwnProperty(validationName) &&
                this.configuration.configuration && this.configuration.configuration.hasOwnProperty(validationName) === false
            ) {
                delete fullValidations.configuration.configuration[validationName]
            }
        }

        return fullValidations
    },
}
</script>
<style lang="scss" scoped>
.configuration-card {
    position: sticky;
    top: 0;
}
</style>
