<template>
    <div class="full-height-layout" v-if="serviceLevelConfigurationData">
        <service-level-configuration-configurator
            :configuration="serviceLevelConfigurationData"
            @saveConfiguration="saveConfiguration"
            :loading="loading"
        ></service-level-configuration-configurator>
    </div>
</template>

<script>
import CONFIG from '@root/config'
import ServiceLevelConfigurationConfigurator from '@/components/carriers/ServiceLevelConfigurationConfigurator'
import { stripProperties } from '@/components/utils/Utils'

export default {
    name: 'ServiceLevelConfigurationDetail',
    components: { ServiceLevelConfigurationConfigurator },
    props: ['carrierData', 'serviceLevelData', 'serviceLevelConfigurationData'],
    data () {
        return {
            localConfiguration: null,
            loading: false,
            hasError: false,
            protectedConfigurationKeys: [
                'carrierId',
                'serviceLevelId',
                'serviceLevelConfigurationId',
                'dateCreated',
                'dateUpdated',
            ],
        }
    },
    computed: {
        configuration: {
            get () {
                if (!this.serviceLevelConfigurationData) {
                    return null
                }

                const data = Object.entries(this.serviceLevelConfigurationData).filter(([key, value]) => !this.protectedConfigurationKeys.includes(key))

                return JSON.stringify(Object.assign(...data.map(([k, v]) => ({ [k]: v }))), null, 2)
            },
            set (newValue) {
                this.hasError = false
                try {
                    this.localConfiguration = JSON.parse(newValue)
                } catch (e) {
                    this.hasError = true
                }
            },
        },
    },

    methods: {
        saveConfiguration (configuration) {
            if (!this.isAllowedTo('updateServiceLevelConfiguration')) {
                return
            }

            this.loading = true
            const jobName = JSON.stringify(this.localConfiguration)

            this.addJob(jobName)
            const url = CONFIG.API.ROUTES.CARRIERS.SERVICELEVELS.CONFIGURATIONS.UPDATE
                .replace('{carrierId}', this.$route.params.carrierId)
                .replace('{serviceLevelId}', this.$route.params.serviceLevelId)
                .replace('{serviceLevelConfigurationId}', this.$route.params.serviceLevelConfigurationId)

            this.$api.post(url, stripProperties(configuration)).then(() => {
                this.$root.$emit('notification:global', {
                    message: `Saving service level configuration completed.`,
                })
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: `Saving service level configuration failed. Please try again.`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.loading = false
                this.finishJob(jobName)
            })
        },
    },
}
</script>

<style lang="scss" scoped>
    .configuration {
        &__textarea {
            max-height: 900px;
            height: 500px;
            font-family: monospace;
        }
    }
</style>
