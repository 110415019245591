<template>
    <div data-test="dimension2d">
        <div class="sl-config__input" v-for="(field, key) in fields" :key="key">
            <mercur-input
                v-model.number="splitted[key]"
                data-test="dimension2dInput"
                type="number"
                :class="{'form-invalid': !isValid(splitted[key])}">
                {{field}}
                <template slot="note">
                    <span class="form-error" v-if="!isValid(splitted[key])">Check this field</span>
                </template>
            </mercur-input>
        </div>
    </div>
</template>

<script>
import ServiceLevelConfigurationMixin from '@/components/carriers/ServiceLevelConfigurations/ServiceLevelConfigurationMixin'
export const validator = value => {
    if (typeof value === 'undefined' || value === null || value === '') {
        return true
    }
    return /\d+(\.\d{1,2})?x\d+(\.\d{1,2})?/.test(value)
}

export default {
    name: 'Dimension2d',
    mixins: [ServiceLevelConfigurationMixin],
    data () {
        return {
            splitted: null,
            fields: ['Length', 'Girth'],
        }
    },
    watch: {
        splitted () {
            this.localValue = this.splitted.join('x')
            this.emitValue()
        },
    },
    methods: {
        isValid (value) {
            return value !== ''
        },
    },
    created () {
        this.splitted = this.localValue.split('x')
    },
}
</script>
