export default {
    props: ['value', 'v'],
    data () {
        return {
            localValue: null,
        }
    },
    methods: {
        emitValue () {
            this.$emit('input', this.localValue)
        },
    },
    created () {
        this.$watch('value', (newValue) => {
            if (!newValue) {
                return
            }

            this.$set(this, 'localValue', JSON.parse(JSON.stringify(newValue)))
        }, {
            immediate: true,
        })
    },
}
