<template>
    <div class="sl-config__section">
        <div class="sl-config__field">
            <mercur-input
                v-model.number="localValue.charge"
                data-test="rangeNumbersCargeInput"
                @input="emitValue"
                type="number"
                :class="{'form-invalid': v.charge.$error}">
                Charge
                <template slot="note">
                    <span class="form-error" v-if="!v.charge.required">Value is required</span>
                </template>
            </mercur-input>
            <mercur-input
                v-model.number="localValue.rangeStart"
                data-test="rangeNumbersRangeStartInput"
                @input="emitValue"
                type="number"
                :class="{'form-invalid': v.rangeStart.$error}">
                Range start
                <template slot="note">
                    <span class="form-error" v-if="!v.rangeStart.required">Value is required</span>
                </template>
            </mercur-input>
            <mercur-input
                v-model.number="localValue.rangeEnd"
                data-test="rangeNumbersRangeEndInput"
                @input="emitValue"
                type="number"
                :class="{'form-invalid': v.rangeEnd.$error}">
                Range end
                <template slot="note">
                    <span class="form-error" v-if="!v.rangeEnd.required">Value is required</span>
                </template>
            </mercur-input>
            <mercur-button data-test="rangeNumbersDeleteRangeButton" class="btn btn-raised btn-icon btn-mini mt-0 sl-config__remove" @click="$emit('deleteRange')">
                <i class="fas fa-trash"></i>
            </mercur-button>
        </div>
    </div>
</template>
<script>
import ServiceLevelConfigurationMixin from '@/components/carriers/ServiceLevelConfigurations/ServiceLevelConfigurationMixin'

export default {
    name: 'RangeNumbers',
    mixins: [ServiceLevelConfigurationMixin],
}
</script>
