<template>
    <div class="sl-config">
        <h2 class="font-weight-normal" :class="{'error': v.$invalid}">
            <slot></slot>
        </h2>
        <div class="sl-config__section">
            <div class="sl-config__field" v-for="(palletName, key) in availablePallets" :key="palletName">
                <label class="sl-config__label">{{palletName}}</label>
                <div class="sl-config__value">
                    <mercur-input
                        data-test="palletCostsDynamicChargeInput"
                        v-model.number="localValue[palletName].charge"
                        @input="emitValue"
                        type="number">
                        Charge
                    </mercur-input>
                    <mercur-button data-test="palletCostsDynamicRemovePalletButton" v-if="key > 0 && availablePallets.length - 1 === key" class="btn btn-icon" @click="removePallet(palletName)">
                        <i class="fas fa-trash"></i>
                    </mercur-button>
                </div>
            </div>
            <div class="sl-config__field" v-if="availablePallets.length < palletNames.length">
                <label class="sl-config__label"></label>
                <div class="sl-config__value">
                    <mercur-button data-test="palletCostsDynamicAddPalletButton" class="btn sl-config__add" @click="addPallet">add pallet +</mercur-button>
                </div>
            </div>
            <div class="sl-config__field" v-if="localValue.additionalPallet">
                <label class="sl-config__label">additionalPallet</label>
                <div class="sl-config__value">
                    <mercur-input
                        v-model.number="localValue.additionalPallet.charge"
                        @input="emitValue"
                        type="number">
                        Charge
                    </mercur-input>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import ServiceLevelConfigurationMixin from '@/components/carriers/ServiceLevelConfigurations/ServiceLevelConfigurationMixin'

export default {
    name: 'PalletCostsDynamic',
    mixins: [ServiceLevelConfigurationMixin],
    data () {
        return {
            palletNames: ['first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh', 'eighth', 'ninth', 'tenth'].map(value => `${value}Pallet`),
        }
    },
    computed: {
        availablePallets () {
            return this.palletNames.filter(palletName => {
                return this.localValue[palletName]
            })
        },
    },
    methods: {
        addPallet () {
            const nextPalletName = this.palletNames[this.availablePallets.length]
            this.$set(this.localValue, nextPalletName, {
                type: 'cost',
                charge: null,
            })
            this.emitValue()
        },
        removePallet (palletName) {
            this.$delete(this.localValue, palletName)
            this.emitValue()
        },
    },
}
</script>
