<template>
    <div data-test="ServiceLevelConfigurationConfiguratorConfigurationFirstPackChargeInput">
        <div class="flex justify-content-between align-items-center" :class="{'error': v.$invalid}">
            <h2 class="font-weight-normal"><slot></slot></h2>
            <mercur-select
                data-test="ServiceLevelConfigurationConfiguratorConfigurationTypeSelect"
                v-if="availableTypes.length > 1"
                class="change-type"
                v-model="type">
                <template slot="label">Type</template>
                <option
                    v-for="availableType in availableTypes"
                    :value="availableType"
                    :key="availableType">
                    {{availableType}}
                </option>
            </mercur-select>
        </div>
        <div v-if="isCost" class="sl-config">
            <div class="sl-config__section">
                <mercur-input
                    data-test="ServiceLevelConfigurationConfiguratorConfigurationCostChargeInput"
                    v-model.number="localValue.charge"
                    @input="emitValue"
                    type="number"
                    :class="{'form-invalid': v.charge.$error}">
                    {{localValue.type | beautify}}
                    <template slot="suffix" v-if="localValue.type === 'percentage'">%</template>
                    <template slot="note">
                        <span class="form-error" v-if="!v.charge.required">Value is required</span>
                    </template>
                </mercur-input>
            </div>
        </div>
        <div v-if="isRanges" data-test="ServiceLevelConfigurationConfiguratorConfigurationRanges" class="sl-config">
            <span class="error" v-if="!v.ranges.minLength || !v.ranges.required">Minimum of 1 range is required</span>
            <div v-for="(range, key) in localValue.ranges" :key="key">
                <component
                    :is="v.ranges.$each[key].$meta && v.ranges.$each[key].$meta.dimensions ? 'range-dimensions' : 'range-numbers'"
                    v-model="localValue.ranges[key]"
                    :data-test="'ServiceLevelConfigurationConfiguratorConfigurationRangeInput'"
                    :v="v.ranges.$each[key]"
                    @deleteRange="deleteRange(key)"
                    @input="emitValue"
                ></component>

            </div>
            <mercur-button
                class="btn btn-raised text-uppercase sl-config__add"
                @click="localValue.ranges.push({charge: null, rangeStart: null, rangeEnd: null}) && emitValue()"
                data-test="ServiceLevelConfigurationConfiguratorConfigurationAddRangeButton">
                add range +
            </mercur-button>
        </div>
        <div v-if="isFirstAndAdditional" class="sl-config">
            <div class="sl-config__section">
                <div class="sl-config__field">
                    <label class="sl-config__label" :class="{'error': v.firstPack.$error}">First pack</label>
                    <div class="sl-config__value">
                        <mercur-input
                            v-model.number="localValue.firstPack.charge"
                            data-test="ServiceLevelConfigurationConfiguratorConfigurationFirstPackChargeInput"
                            @input="emitValue"
                            type="number"
                            :class="{'form-invalid': v.firstPack.$error}">
                            First pack
                            <template slot="note">
                                <span class="form-error" v-if="v.firstPack.$error">Value is required</span>
                            </template>
                        </mercur-input>
                    </div>
                </div>
                <div class="sl-config__field">
                    <label class="sl-config__label" :class="{'error': v.extraPack.$error}">Extra pack</label>
                    <div class="sl-config__value">
                        <mercur-input
                            v-model.number="localValue.extraPack.charge"
                            data-test="ServiceLevelConfigurationConfiguratorConfigurationExtraPackChargeInput"
                            @input="emitValue"
                            type="number"
                            :class="{'form-invalid': v.extraPack.$error}">
                            Additional pack
                            <template slot="note">
                                <span class="form-error" v-if="v.extraPack.$error">Value is required</span>
                            </template>
                        </mercur-input>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ServiceLevelConfigurationMixin from '@/components/carriers/ServiceLevelConfigurations/ServiceLevelConfigurationMixin'
import RangeNumbers from '@/components/carriers/ServiceLevelConfigurations/Fields/RangeNumbers'
import RangeDimensions from '@/components/carriers/ServiceLevelConfigurations/Fields/RangeDimensions'

export default {
    name: 'RangeChargesDynamic',
    components: { RangeDimensions, RangeNumbers },
    mixins: [ServiceLevelConfigurationMixin],
    computed: {
        availableTypes () {
            const types = []
            if (this.v.hasOwnProperty('ranges')) {
                types.push('ranges')
            }

            if (this.v.hasOwnProperty('charge')) {
                types.push('cost')
            }

            if (this.v.hasOwnProperty('firstPack')) {
                types.push('firstAndAdditional')
            }

            return types
        },
        isCost () {
            return this.type === 'cost'
        },
        isRanges () {
            return this.type === 'ranges'
        },
        isFirstAndAdditional () {
            return this.type === 'firstAndAdditional'
        },
        type: {
            get () {
                if (!this.localValue) {
                    return null
                }

                if (this.localValue.ranges) {
                    return 'ranges'
                }

                if (this.localValue.firstPack) {
                    return 'firstAndAdditional'
                }

                return 'cost'
            },
            set (newType) {
                if (this.type === newType) {
                    return
                }
                if (newType !== 'cost') {
                    this.$delete(this.localValue, 'type')
                    this.$delete(this.localValue, 'charge')
                }

                if (newType !== 'ranges') {
                    this.$delete(this.localValue, 'ranges')
                }

                if (newType !== 'firstAndAdditional') {
                    this.$delete(this.localValue, 'firstPack')
                    this.$delete(this.localValue, 'extraPack')
                }

                if (newType === 'firstAndAdditional') {
                    this.$set(this.localValue, 'firstPack', {
                        charge: null,
                        type: 'cost',
                    })
                    this.$set(this.localValue, 'extraPack', {
                        charge: null,
                        type: 'cost',
                    })
                }

                if (newType === 'ranges') {
                    this.$set(this.localValue, 'ranges', [])
                }

                if (newType === 'cost') {
                    this.$set(this.localValue, 'type', 'cost')
                    this.$set(this.localValue, 'charge', null)
                }

                this.emitValue()
            },
        },
    },
    methods: {
        deleteRange (key) {
            this.$delete(this.localValue.ranges, key)
            this.emitValue()
        },
    },
}
</script>
