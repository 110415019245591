<template>
    <div class="sl-config">
        <h2 class="font-weight-normal" :class="{'error': v.$invalid}">
            <slot></slot>
        </h2>
        <div class="sl-config__section sl-config__field">
            <mercur-input
                data-test="limitDynamicInput"
                v-model.number="localValue.limit"
                @input="emitValue"
                type="number"
                :class="{'form-invalid': v.$error}">
                Limit
                <template slot="note">
                    <span class="form-error" v-if="!v.required">Value is required</span>
                </template>
            </mercur-input>
        </div>
    </div>
</template>
<script>

import ServiceLevelConfigurationMixin from '@/components/carriers/ServiceLevelConfigurations/ServiceLevelConfigurationMixin'

export default {
    name: 'LimitDynamic',
    mixins: [ServiceLevelConfigurationMixin],
}
</script>
