<template>
    <div data-test="surchargesDynamic" class="sl-config">
        <div class="flex justify-content-between align-items-center" :class="{'error': v.$invalid}">
            <h2 class="font-weight-normal"><slot></slot></h2>
            <mercur-button
                v-if="v.hasOwnProperty('type')"
                data-test="surchargesDynamicChangeTypeButton"
                class="btn btn-raised text-uppercase"
                @click="switchType"
            >Change to {{isAmount ? 'percentage' : isPercentage ? 'amount' : '' }}</mercur-button>
        </div>
        <div class="sl-config__section sl-config__field">
            <mercur-input
                v-model.number="localValue.charge"
                data-test="surchargesDynamicChargeInput"
                @input="emitValue"
                type="number"
                :class="{'form-invalid': v.$error}">
                Surcharge
                <template slot="suffix">{{isPercentage ? '%' : ''}}</template>
                <template slot="note">
                    <span class="form-error" v-if="!v.charge.required">Value is required</span>
                </template>
            </mercur-input>
        </div>
    </div>
</template>
<script>

import ServiceLevelConfigurationMixin from '@/components/carriers/ServiceLevelConfigurations/ServiceLevelConfigurationMixin'

export const TYPES = {
    TYPE_AMOUNT: 'TYPE_AMOUNT',
    TYPE_PERCENTAGE: 'TYPE_PERCENTAGE',
}

export default {
    name: 'SurchargesDynamic',
    mixins: [ServiceLevelConfigurationMixin],
    computed: {
        isAmount () {
            return this.chargeType === TYPES.TYPE_AMOUNT
        },
        isPercentage () {
            return this.chargeType === TYPES.TYPE_PERCENTAGE
        },
        chargeType: {
            get () {
                return this.localValue && this.localValue.type === 'amount' ? TYPES.TYPE_AMOUNT : TYPES.TYPE_PERCENTAGE
            },
            set (newType) {
                this.$set(this.localValue, 'type', newType === TYPES.TYPE_AMOUNT ? 'amount' : 'percentage')
                this.emitValue()
            },
        },
    },
    methods: {
        switchType () {
            this.chargeType = this.chargeType === TYPES.TYPE_AMOUNT ? TYPES.TYPE_PERCENTAGE : TYPES.TYPE_AMOUNT
        },
    },
}
</script>
