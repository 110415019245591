<template>
    <div class="sl-config__section">
        <mercur-input
            v-model.number="localValue.charge"
            data-test="rangeDimensionsCargeInput"
            @input="emitValue"
            type="number"
            :class="{'form-invalid': v.charge.$error}"
            class="mr-4">
            Charge
            <template slot="note">
                <span class="form-error" v-if="!v.charge.required">Value is required</span>
            </template>
        </mercur-input>

        <div class="sl-config__field">
            <label class="sl-config__label" :class="{'error': v.rangeStart.$error}">Range start</label>
            <component :is="v.$meta.dimensions2d ? 'dimension-2d' : 'dimension-3d'" class="sl-config__value" v-model="localValue.rangeStart" @input="emitValue" :v="v.rangeStart"></component>
        </div>
        <div class="sl-config__field">
            <label class="sl-config__label" :class="{'error': v.rangeEnd.$error}">Range end</label>
            <component :is="v.$meta.dimensions2d ? 'dimension-2d' : 'dimension-3d'" class="sl-config__value" v-model="localValue.rangeEnd" @input="emitValue" :v="v.rangeEnd"></component>
        </div>
        <mercur-button class="btn btn-raised btn-icon btn-mini mt-0 sl-config__remove" @click="$emit('deleteRange')">
            <i class="fas fa-trash"></i>
        </mercur-button>
    </div>
</template>
<script>
import ServiceLevelConfigurationMixin from '@/components/carriers/ServiceLevelConfigurations/ServiceLevelConfigurationMixin'
import Dimension2d from '@/components/carriers/ServiceLevelConfigurations/Fields/Dimension2d'
import Dimension3d from '@/components/carriers/ServiceLevelConfigurations/Fields/Dimension3d'

export default {
    name: 'RangeDimensions',
    components: { Dimension3d, Dimension2d },
    mixins: [ServiceLevelConfigurationMixin],
}
</script>
